import React from 'react';
import { Sheet, Table, Modal, ModalDialog, Grid } from '@mui/joy';

function RewardTable({ onClose, open, rewards }) {
  return (
    <Modal open={open} onClose={onClose} style={{ zIndex: "1000" }}>
      <ModalDialog sx={{ width: "800px", borderRadius: "12px", padding: "40px" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Sheet sx={{ overflow: 'auto', maxHeight: "80vh"}}>
                <Table size="sm" stickyHeader hoverRow
                  sx={{
                    overflow: 'auto',
                    '& tbody tr:hover': {
                      backgroundColor: 'rgba(255,255,255, 0.1)',
                      borderRadius: "18px",
                    },
                    '& tr > *:first-child': {
                      position: 'sticky',
                      left: 0,
                      '@media (max-width:600px)': {
                        bgcolor: 'background.surface',
                        zIndex: 100
                      },
                    },
                    '@media (max-width:600px)': { 
                      width: 'max-content',
                    },
                  }}>
                  <thead>
                    <tr>
                      <th>Název položky</th>
                      <th>Počet výher</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewards.map((reward, index) => (
                      <tr key={index}>
                        <td>{reward['Název položky']}</td>
                        <td>{reward['Počet výher']}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Sheet>
            </div>
          </Grid>
        </Grid>
      </ModalDialog>
    </Modal>
  );
}

export default RewardTable;
