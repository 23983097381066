import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {

  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const target = new Date(targetDate).getTime();
    const timeRemaining = target - now;

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining / 1000) % 60);

    // Přidává nuly na začátek, pokud je číslo menší než 10
    const format = (num) => num.toString().padStart(2, '0');

    return { days: format(days), hours: format(hours), minutes: format(minutes), seconds: format(seconds) };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{fontSize: "2rem", fontFamily: "Oxanium", fontWeight: "800", paddingLeft: "15px"}}>
     Vyhlášení bude za... {timeRemaining.days}:{timeRemaining.hours}:{timeRemaining.minutes}:{timeRemaining.seconds}
    </div>
  );
};

export default Countdown;
